import Helmet from "react-helmet"
import React from "react"
import ExtendedHomepage from "src/components/extendedHomepage"
import HomepageLayout from "src/layouts/homepage"
import FFDisclosure from "src/components/ffdisclosure"
import CtaDisclosureNewtab from "@tightrope/ctadisclosurenewtab"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"


const homepageData = import("./homepage.json");

export default function HomePage() {
  let disclosure = null;
  if(isChrome()){
    disclosure = <CtaDisclosureNewtab data={homepageData}></CtaDisclosureNewtab>;
  }
  else{
    disclosure = <FFDisclosure data={homepageData}></FFDisclosure>;
  }
  return(
    <HomepageLayout>
      <Helmet>
      <style type="text/css">{`
        
        #ctadisclosurenewtab-module--ctadisclaimer {
          padding:0;
          margin:0;
        }
        
                .footerlinkssingle-module--br {
                  background: #f1f1f1;
                  padding: 10px;
                }
        
                .disc {
                  position:relative;
                  margin:0 auto;
                  width:380px;
                  color:#000;
                }
        
                .extendedhomepage-module--top .disc {
                  margin:0;
                }
        
                .disc p {
                  color:#000;
                  font-weight:300;
                  font-size:13px;
                  letter-spacing:normal;
                }
        
                .disc p a {
                  color:#000;
                  font-weight:400;
                }

        `}</style>
      <title>Get Easy Share</title></Helmet>
      <ExtendedHomepage data={homepageData}>
      <div className="disc">{disclosure}</div>
      </ExtendedHomepage>
    </HomepageLayout>
  )
}
